<div class="flex flex-col w-full -mx-5 -mt-5">
    <div
        class="h-3 bg-lime-green w-6/12 rounded-r-full"
    ></div>
</div>

<div class="account-container mt-6">
    <div class="flex justify-center">
        <p class="mx-auto uppercase text-sm font-light">Step 2 of 4</p>
    </div>

    <section class="mb-8">
        <h1 class="mb-4 text-3xl font-semibold">Hello, {{ firstName }}!</h1>
        <div class="text-black">
            <div>
                Before you start your application, please check you meet the following criteria:
            </div>

            <ul class="mt-8 flex flex-col space-y-8">
                <li class="flex items-start" *ngFor="let row of this.openBankingReasons" >
                    <img
                        class="mr-4"
                        width="20px"
                        height="20px"
                        ngSrc="assets/svg/bullet-tick.svg"
                        alt=""
                    />
                    <div>
                        <div class="font-semibold">
                            {{ row.main }}
                        </div>
                        <div class="mt-2 text-black text-opacity-80 text-justify">
                            {{ row.details }}
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <a
            class="btn mx-auto mb-8 mt-14 block h-14 w-full self-stretch rounded-full bg-electric-blue text-center uppercase text-white flex"
            [routerLink]="this.forwardUrl"
        >
            Start application
        </a>
    </section>
</div>

<section class="flex my-8 mx-auto max-w-lg" nomargin>
    <app-noggin-phone-tile
        caption="Why are we doing this?"
        heading="5.8 million people in the UK are excluded from credit!"
        text="We are dedicated to bringing a solution for people unfairly locked out of the current system."
    ></app-noggin-phone-tile>
</section>
