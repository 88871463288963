import { Inject, NgModule } from "@angular/core";
import {
    ActivatedRoute,
    NavigationEnd,
    Router,
    RouterModule,
    RouterState,
    Routes,
    Scroll,
} from "@angular/router";
import { LoginComponent } from "./user/pages/login/login.component";
import { ForgottenPasswordComponent } from "./user/pages/forgotten-password/forgotten-password.component";
import { SignUpComponent } from "./user/pages/sign-up/sign-up.component";
import { ShareBankingDataComponent } from "./user/components/share-banking-data/share-banking-data.component";
import { AuthGuard } from "./user/guards/auth.guard";
import { VipOfferComponent } from "./user/pages/vip-offer/vip-offer.component";
import { offerGuard } from "./user/guards/offer.guard";
import { AccountComponent } from "./user/pages/account/account.component";
import { EditMembershipComponent } from "./user/pages/edit-membership/edit-membership.component";
import { DOCUMENT, Location } from "@angular/common";
import { EditAccountComponent } from "./user/pages/edit-account/edit-account.component";
import { MobileSelectionComponent } from "./deal/pages/mobile-selection/mobile-selection.component";
import { HomeComponent } from "./core/pages/home/home.component";
import { MainScaffoldComponent } from "./core/components/main-scaffold/main-scaffold.component";
import { DealListComponent } from "./deal/pages/deal-list/deal-list.component";
import { MobileCompareComponent } from "./core/pages/mobile-compare/mobile-compare.component";
import { DealDetailsPageComponent } from "./deal/pages/deal-details-page/deal-details-page.component";
import { ArticleListComponent } from "./blog/pages/article-list/article-list.component";
import { ArticleViewComponent } from "./blog/pages/article-view/article-view.component";
import { WebsiteCmsPageWrapperComponent } from "./core/components/website-cms-page-wrapper/website-cms-page-wrapper.component";
import { FaqPageComponent } from "./core/pages/faq-page/faq-page.component";
import { ContactComponent } from "./contact/pages/contact/contact.component";
import { NotFoundComponent } from "./core/pages/not-found/not-found.component";
import { SimDetailsComponent } from "./deal/components/sim-details/sim-details.component";
import { ViewportScroller } from "@angular/common";
import { filter, tap } from "rxjs";
import { DealType } from "./deal/models/filter";
import { OurResearchPageComponent } from "./our-research/components/our-research-page/our-research-page.component";
import { Title } from "@angular/platform-browser";
import { ContactType } from "./contact/models/contact-type";
import { CookiesPageComponent } from "./core/pages/cookies-page/cookies-page.component";
import { TypeformPageComponent } from "./contact/pages/typeform-page/typeform-page.component";
import { NoCreditCheckPhoneComponent } from "./noggin-phone/no-credit-check-phone/no-credit-check-phone.component";
import { CreateAccountComponent } from "./onboarding/pages/create-account/create-account.component";
import { OnboardingScaffoldComponent } from "./onboarding/components/onboarding-scaffold/onboarding-scaffold.component";
import { ShareOpenBankingComponent } from "./onboarding/pages/share-open-banking/share-open-banking.component";
import { FinishMyApplicationComponent } from "./onboarding/pages/finish-my-application/finish-my-application.component";
import { OpenBankingRequiredGuard } from "./user/guards/openBankingRequired.guard";
import { TrustpilotcodeComponent } from "./src/app/core/pages/trustpilotcode/trustpilotcode.component";
import { CustomContentOption } from "./noggin-phone/no-credit-check-phone/no-credit-check-phone.model";
import { MainScaffoldSimpleComponent } from "./core/components/main-scaffold-simple/main-scaffold-simple.component";
import { ApplyNogginPhoneStartComponent } from "./onboarding/pages/apply-noggin-phone-start/apply-noggin-phone-start.component";

export const routeNames = {
    trustpilotcode: {
        path: "68f35889-fb78-4952-a6b0-7f50c874d858.html",
        fullPath: () => "/68f35889-fb78-4952-a6b0-7f50c874d858.html",
    },
    login: {
        path: "login",
        fullPath: () => "/login",
    },
    forgottenPassword: {
        path: "forgotten-password",
        fullPath: () => "/forgotten-password",
    },
    getStarted: {
        createAccount: {
            path: "get-started/create-account",
            fullPath: () => "/get-started/create-account",
        },
        beforeYouStart: {
            path: "get-started/before-you-start",
            fullPath: () => "/get-started/before-you-start",
        },
        shareOpenBanking: {
            path: "get-started/share-open-banking",
            fullPath: () => "/get-started/share-open-banking",
        },
        finishMyApplication: {
            path: "get-started/finish-my-application",
            fullPath: () => "/get-started/finish-my-application",
        },
    },
    signUp: {
        fullPath: () => "/sign-up",
        path: "sign-up",
    },
    shareOpenBanking: {
        path: "share-open-banking",
        fullPath: () => "/share-open-banking",
    },
    vipOffer: {
        path: "vip-offer/:id",
        fullPath: (id: string) => "/vip-offer/" + id,
    },
    account: {
        details: {
            path: "account",
            fullPath: () => "/account",
        },
        editMembership: {
            fullPath: () => "/account/edit-membership",
            path: "account/edit-membership",
        },
        editAccount: {
            fullPath: () => "/account/edit-account",
            path: "account/edit-account",
        },
    },
    home: {
        fullPath: () => "/",
        path: "",
    },
    simFreePhoneSelect: {
        path: "compare/mobile/sim-free-phones",
        fullPath: () => "/compare/mobile/sim-free-phones",
    },
    bundle: {
        selectPhone: {
            path: "compare/mobile/phone-contracts",
            fullPath: () => "/compare/mobile/phone-contracts",
        },
        list: {
            path: "compare/mobile/phone-contracts/:phone_model",
            fullPath: ({ phoneModel }: { phoneModel: string }) =>
                `/compare/mobile/phone-contracts/${phoneModel}`,
        },
        details: {
            path: "compare/mobiles/phone-contracts/:phone_model/:id",
            fullPath: ({ phoneModel, dealId }: { phoneModel: string; dealId: string }) =>
                `/compare/mobiles/phone-contracts/${phoneModel}/${dealId}`,
        },
    },
    simFreeDeals: {
        list: {
            path: "compare/mobiles/sim-free-phones/:phone_model",
            fullPath: ({ phoneModel }: { phoneModel: string }) =>
                `/compare/mobiles/sim-free-phones/${phoneModel}`,
        },
        details: {
            path: "compare/mobiles/sim-free-phones/:phone_model/:id",
            fullPath: ({ phoneModel, dealId }: { phoneModel: string; dealId: string }) =>
                `/compare/mobiles/sim-free-phones/${phoneModel}/${dealId}`,
        },
    },

    simOnlyDeals: {
        list: {
            path: "compare/mobiles/sim-only-deals",
            fullPath: () => `/compare/mobiles/sim-only-deals`,
        },
        details: {
            path: "compare/mobiles/sim-only-deals/:id",
            fullPath: ({ dealId }: { dealId: string }) =>
                `/compare/mobiles/sim-only-deals/${dealId}`,
        },
    },
    articles: {
        list: {
            path: "blogs",
            fullPath: () => "/blogs",
        },
        details: {
            path: "blogs/:slug/:id",
            fullPath: (slug: string, id: string) => `/blogs/${slug}/${id}`,
        },
    },
    privacy: {
        fullPath: () => "/privacy",
        path: "privacy",
    },
    terms: {
        path: "terms-and-conditions",
        fullPath: () => "/terms-and-conditions",
    },
    cookies: {
        path: "cookie-policy",
        fullPath: () => "/cookie-policy",
    },

    about: {
        fullPath: () => "/about-us",
        path: "about-us",
    },
    faq: {
        fullPath: () => "/faqs",
        path: "faqs",
    },
    compare: {
        default: {
            fullPath: () => "/compare",
            path: "compare",
        },
        mobile: {
            fullPath: () => "/compare/mobile",
            path: "compare/mobile",
        },
    },
    noCreditCheckPhones: {
        path: "no-credit-check-phones",
        fullPath: () => "/no-credit-check-phones",
    },
    noCreditCheckPhonesStudents: {
        path: "get-your-student-phone-contract",
        fullPath: () => "/get-your-student-phone-contract",
    },
    noCreditCheckPhonesThinFile: {
        path: "phone-contracts-thin-file",
        fullPath: () => "/phone-contracts-thin-file",
    },
    noCreditCheckPhonesHandsetOnly: {
        path: "handset-only-phone-contracts",
        fullPath: () => "/handset-only-phone-contracts",
    },
    noCreditCheckPhonesBadCredit: {
        path: "bad-credit-phone-contracts",
        fullPath: () => "/bad-credit-phone-contracts",
    },
    noCreditCheckPhonesRenters: {
        path: "phone-contracts-for-renters",
        fullPath: () => "/phone-contracts-for-renters",
    },
    noCreditCheckPhonesNewToCountry: {
        path: "phone-contracts-new-to-country",
        fullPath: () => "/phone-contracts-new-to-country",
    },
    contact: {
        path: "contact-us",
        fullPath: () => "/contact-us",
    },
    typeform: {
        path: "compare/mobile/no-credit-check-early-application",
        fullPath: () => "/compare/mobile/no-credit-check-early-application",
    },
    complaints: {
        path: "complaints",
        fullPath: () => "/complaints",
    },
    notFound: {
        path: "404",
        fullPath: () => "/404",
    },
    ourResearch: {
        path: "our-research",
        fullPath: () => "/our-research",
    },
} as const;

const routes: Routes = [
    {
        path: routeNames.trustpilotcode.path,
        component: TrustpilotcodeComponent,
        title: "Noggin | Find a No Credit Check With Us",
    },
    {
        path: routeNames.typeform.path,
        canActivate: [OpenBankingRequiredGuard],
        component: TypeformPageComponent,
        title: "Noggin | Find a No Credit Check With Us",
    },
    {
        path: routeNames.login.path,
        component: LoginComponent,
        title: "Noggin | Login",
    },
    {
        path: routeNames.ourResearch.path,
        component: OurResearchPageComponent,
        title: "Noggin | Our Research",
    },
    {
        path: routeNames.forgottenPassword.path,
        component: ForgottenPasswordComponent,
        title: "Noggin | forgotten-password",
    },
    {
        path: routeNames.signUp.path, // TODO: Remove this route
        component: SignUpComponent,
        title: "Noggin | Sign Up For Noggin",
    },
    {
        path: routeNames.shareOpenBanking.path,
        component: ShareBankingDataComponent,
        title: "Noggin | Share Open Banking",
        canActivate: [AuthGuard],
    },
    {
        path: routeNames.vipOffer.path,
        component: VipOfferComponent,
        title: "Noggin | VIP Offer",
        canActivate: [AuthGuard, offerGuard],
    },
    {
        path: routeNames.account.details.path,
        component: AccountComponent,
        canActivate: [AuthGuard],
        title: "Noggin | Account",
    },
    {
        path: routeNames.account.editMembership.path,
        component: EditMembershipComponent,
        canActivate: [AuthGuard],
        title: "Noggin | Edit Membership",
    },
    {
        path: routeNames.account.editAccount.path,
        component: EditAccountComponent,
        canActivate: [AuthGuard],
        title: "Noggin | Edit Account",
    },
    {
        path: routeNames.bundle.selectPhone.path,
        loadComponent: () => MobileSelectionComponent,
        title: "Noggin | See Our Best SIM & Mobile Deals for You",
        data: { dealType: DealType.bundle },
    },

    {
        path: routeNames.simFreePhoneSelect.path,
        loadComponent: () => MobileSelectionComponent,
        title: "Noggin | See Our Best SIM & Mobile Deals for You",
        data: { dealType: DealType.phoneOnly },
    },
    {
        path: routeNames.home.path,
        redirectTo: routeNames.compare.mobile.path,
        pathMatch: "full",
    },
    {
        path: routeNames.compare.default.path,
        redirectTo: routeNames.compare.mobile.path,
        pathMatch: "full",
    },
    {
        path: "",
        component: MainScaffoldComponent,
        children: [
            {
                path: routeNames.bundle.list.path,
                component: DealListComponent,
                data: { dealType: DealType.bundle },
                title: "Noggin | See Our Best SIM & Mobile Deals for You",
            },
            {
                path: routeNames.bundle.details.path,
                component: DealDetailsPageComponent,
                data: { dealType: DealType.bundle },
                title: "Noggin | Bundle Deal",
            },
            {
                path: routeNames.simOnlyDeals.list.path,
                component: DealListComponent,
                data: { dealType: DealType.simOnly },
                title: "Noggin | SIM Deal",
            },

            {
                path: routeNames.simOnlyDeals.details.path,
                component: SimDetailsComponent,
                data: { dealType: DealType.simOnly },
                title: "Noggin | SIM Deal",
            },
            {
                path: routeNames.simFreeDeals.details.path,
                component: DealDetailsPageComponent,
                data: { dealType: DealType.phoneOnly },
                title: "Noggin | Phone only deal",
            },

            {
                path: routeNames.simFreeDeals.list.path,
                component: DealListComponent,
                data: { dealType: DealType.phoneOnly },
                title: "Noggin | See Our Best SIM & Mobile Deals for You",
            },
            {
                path: routeNames.articles.list.path,
                component: ArticleListComponent,
                title: "Noggin | Blogs",
            },
            {
                path: routeNames.articles.details.path,
                component: ArticleViewComponent,
                title: "Noggin | Blogs",
            },

            {
                path: routeNames.privacy.path,
                component: WebsiteCmsPageWrapperComponent,
                title: "Noggin | Our Privacy Policy",
            },
            {
                path: routeNames.terms.path,
                component: WebsiteCmsPageWrapperComponent,
                data: { slug: "terms" },
                title: "Noggin | Our Terms of Service",
            },
            {
                path: routeNames.cookies.path,
                data: { slug: "cookies" },
                component: CookiesPageComponent,
                title: "Noggin | Our Cookies Policy",
            },

            {
                path: routeNames.faq.path,
                component: FaqPageComponent,
                title: "Noggin | FAQ",
            },
            {
                path: routeNames.contact.path,
                component: ContactComponent,
                data: { type: ContactType.contact },
                title: "Noggin | Contact Us",
            },

            {
                path: routeNames.complaints.path,
                data: { type: ContactType.complaints },
                component: ContactComponent,
                title: "Noggin | Complaints",
            },
        ]
    },
    {
        path: "",
        component: MainScaffoldSimpleComponent,
        data: {
            footerColor: "bg-off-white",
        },
        children: [
            {
                path: routeNames.compare.mobile.path,
                component: MobileCompareComponent,
                title: "Noggin | See Our Best SIM & Mobile Deals for You",
            },
        ]
    },
    {
        path: "",
        component: MainScaffoldSimpleComponent,
        children: [
            {
                path: routeNames.noCreditCheckPhones.path,
                component: NoCreditCheckPhoneComponent,
                title: "Noggin | No Credit Check Phones",
                data: {
                    contentOption: CustomContentOption.DEFAULT,
                }
            },
            {
                path: routeNames.noCreditCheckPhonesStudents.path,
                component: NoCreditCheckPhoneComponent,
                title: "Noggin | No Credit Check Phones for students",
                data: {
                    contentOption: CustomContentOption.STUDENT,
                }
            },
            {
                path: routeNames.noCreditCheckPhonesThinFile.path,
                component: NoCreditCheckPhoneComponent,
                title: "Noggin | Phone contracts for people with thin credit files",
                data: {
                    contentOption: CustomContentOption.THIN_FILE,
                }
            },
            {
                path: routeNames.noCreditCheckPhonesHandsetOnly.path,
                component: NoCreditCheckPhoneComponent,
                title: "Noggin | Handset-only phone contracts",
                data: {
                    contentOption: CustomContentOption.HANDSET_ONLY,
                }
            },
            {
                path: routeNames.noCreditCheckPhonesBadCredit.path,
                component: NoCreditCheckPhoneComponent,
                title: "Noggin | Phone contracts for people with bad credit",
                data: {
                    contentOption: CustomContentOption.BAD_CREDIT,
                }
            },
            {
                path: routeNames.noCreditCheckPhonesRenters.path,
                component: NoCreditCheckPhoneComponent,
                title: "Noggin | Phone contracts built for renters",
                data: {
                    contentOption: CustomContentOption.RENTERS,
                }
            },
            {
                path: routeNames.noCreditCheckPhonesNewToCountry.path,
                component: NoCreditCheckPhoneComponent,
                title: "Noggin | Phone contracts for people new to the UK",
                data: {
                    contentOption: CustomContentOption.NEW_TO_COUNTRY,
                }
            },
        ],
    },
    {
        path: "",
        component: OnboardingScaffoldComponent,
        children: [
            {
                path: routeNames.getStarted.createAccount.path,
                component: CreateAccountComponent,
                title: "Noggin | Create your Noggin account",
            },
            {
                path: routeNames.getStarted.beforeYouStart.path,
                canActivate: [AuthGuard],
                component: ApplyNogginPhoneStartComponent,
                title: "Noggin | Apply for No Credit Check Phone",
            },
            {
                path: routeNames.getStarted.shareOpenBanking.path,
                canActivate: [AuthGuard],
                component: ShareOpenBankingComponent,
                title: "Noggin | Share Open Banking",
            },
            {
                path: routeNames.getStarted.finishMyApplication.path,
                canActivate: [AuthGuard],
                component: FinishMyApplicationComponent,
                title: "Noggin | Finish My Application",
            }
        ]
    },
    {
        path: routeNames.about.path,
        redirectTo: routeNames.ourResearch.path,
    },
    {
        path: routeNames.notFound.path,
        component: NotFoundComponent,
        title: "404 NOT FOUND",
    },
    { path: "**", redirectTo: routeNames.notFound.path },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            useHash: false,
            scrollPositionRestoration: "disabled",
            anchorScrolling: "enabled",
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
    constructor(
        private router: Router,
        private titleService: Title,
        viewportScroller: ViewportScroller,
        @Inject(DOCUMENT) private document: Document,
    ) {
        router.events.pipe(filter((e): e is Scroll => e instanceof Scroll)).subscribe((e) => {
            const event = e as unknown as Scroll;
            if (event.position) {
                // backward navigation
                viewportScroller.scrollToPosition(event.position);
            } else if (event.anchor) {
                // anchor navigation
                viewportScroller.scrollToAnchor(event.anchor);
            } else {
                // forward navigation
                viewportScroller.scrollToPosition([0, 0]);
            }
        });
        router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe((event) => {
            event = event as NavigationEnd;
            const title = this.getTitle(this.router.routerState, this.router.routerState.root).join(
                "-",
            );
            this.titleService.setTitle(title);

            gtag("config", "G-NTS1P3LE2R", {
                page_title: title,
                page_path: event.urlAfterRedirects,
                page_location: this.document.location.href,
            });
        });
    }

    private getTitle(state: RouterState, parent: ActivatedRoute): string[] {
        const data = [];
        if (parent && parent.snapshot.title && parent.snapshot.title) {
            data.push(parent.snapshot.title);
        }
        if (state && parent && parent.firstChild) {
            data.push(...this.getTitle(state, parent.firstChild));
        }
        return data;
    }
}
